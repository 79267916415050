import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ModAnimation from "../../../components/Chronology/Modules/ModAnimation";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModDotList from "../../../components/Chronology/Modules/ModDotList";

export const frontmatter = {
  title: "Día 80",
  week: "Semana 12",
  day: "01",
  month: "Jun",
  monthNumber: "06",
  date: "2020-06-01",
  path: "/cronologia/semana-12#dia-01-jun/",
};

const Day80 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModDailyHighlight>
          Sexto día del periodo de luto oficial
        </ModDailyHighlight>
        <ModText>
          En los últimos siete días se han diagnosticado 2,760 casos mediante
          pruebas PCR. En ese mismo periodo, 268 personas han requerido
          hospitalización (9,7% de los diagnósticos), de los que nueve han sido
          ingresos en la UCI.
        </ModText>
        <ModText>
          El BOE ha hecho públicas las{" "}
          <InlineLink link="https://www.boe.es/boe/dias/2020/05/30/pdfs/BOE-A-2020-5469.pdf">
            medidas de flexibilización para la Fase 3 del Plan de Transición
          </InlineLink>
          . Entre estas, podemos encontrar:
        </ModText>
        <ModDotList>
          No quedará reservada ninguna franja horaria a ningún colectivo.
        </ModDotList>
        <ModDotList>
          Las terrazas al aire libre pueden abrir al 75% de su capacidad.
        </ModDotList>
        <ModDotList>
          textoPodrán abrirse los establecimientos y locales comerciales con
          independencia de su superficie útil, siempre y cuando se limite su
          aforo al 50%.
        </ModDotList>
        <ModDotList>
          La actividad de los cines, teatros y espacios similares podrán
          desarrollar su actividad siempre que cuenten con butacas pre-asignadas
          y no superen la mitad del aforo autorizado en cada sala.
        </ModDotList>
        <ModDotList>
          En hoteles y alojamientos turísticos se permite la apertura al público
          de las zonas comunes siempre que no superen el 50% de su aforo.
        </ModDotList>
        <ModTwoCols
          src="/images/svg/1004-government-digital-money.svg"
          alt="creditos blandos covid-19"
          small={false}
          inverted={true}
        >
          Además, a partir de hoy, queda plenamente disponible la{" "}
          <InlineLink link="https://www.mitma.gob.es/el-ministerio/sala-de-prensa/noticias/vie-29052020-2141">
            línea de microcréditos
          </InlineLink>{" "}
          avalados y al 0% de interés para el{" "}
          <strong>pago del alquiler de vivienda habitual</strong>. Hasta el
          momento, ya son once entidades financieras las que han formalizado el
          contrato de adhesión, para ayudar a personas y familias que hayan
          visto reducidas sus rentas a causa de la crisis de COVID-19.
        </ModTwoCols>
        <ModText>
          También se ha reanudado el cómputo de los plazos administrativos en
          tramités con entidades del sector público, que habían quedado
          suspendidos tras decretarse el estado de alarma el pasado 14 de marzo.
        </ModText>
        <ModAnimation svg="/images/anim/03_abr_predecir_corona.svg" />
        <ModText>
          Actualmente se están llevando a cabo varios proyectos de investigación
          del coronvirus en España. Puedes consultar o ampliar información de
          cada uno de ellos en el{" "}
          <InlineLink link="https://www.ciencia.gob.es/portal/site/MICINN/menuitem.26172fcf4eb029fa6ec7da6901432ea0/?vgnextoid=32ea8640bf1f0710VgnVCM1000001d04140aRCRD">
            portal de Ministerio de Investigación y Ciencia.
          </InlineLink>
          <ModReferenceList title="Guías y documentos publicados">
            <ReferenceRow
              link="https://www.lamoncloa.gob.es/Documents/31052020PlanTransicionGuiaFase3.pdf"
              name="Guía para la Fase 3"
            />
          </ModReferenceList>
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day80;
